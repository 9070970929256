import { createSlice } from "@reduxjs/toolkit";
import { getUserData } from "../utils/localStorage";

const initialState = {
  accessToken: getUserData().token,
  isSidebarOpen: true,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.accessToken = action.payload;
    },
    clearToken: (state) => {
      state.accessToken = null;
    },
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
  },
});

export const { setToken, clearToken, toggleSidebar } = authSlice.actions;

export default authSlice.reducer;
