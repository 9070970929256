import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import {
  garmentStatusUpdate,
  uploadLogo,
} from "../services/garmentCategory";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { errorMessage, successMessage } from "../utils/alertMessage";
import { getAppService, updateAppService, uploadAppServiceLogo } from "../services/appServices";

const AppServicesView = () => {
  const navigate = useNavigate();
  // const userData = getUserData();
  // let brand = userData.brandProfileData;
  // let user = userData.userProfileData;

  const [loading, setLoading] = useState(false);

  const [appServiceList, setAppServiceList] = useState([]);
  const [enableDisableStatus, setEnableDisableStatus] = useState({});
  const [fileName, setFileName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [displayAppServiceLogoUpload, setDisplayAppServiceLogoUpload] =
    useState(false);
  const [AppServicePreviousLogo, setAppServicePreviousLogo] = useState(null);
  const [selectedAppServiceId, setSelectedAppServiceId] = useState(null);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);
  

 const fetchAppService = () => {
   setLoading(true);
   getAppService()
     .then((response) => {
       if (!response.data.error) {
         const Res = response.data;
         setAppServiceList(Res);
         setLoading(false);
         setSubmitButtonPressed(false);
       }
     })
     .catch((err) => {
       console.error("Error:", err);
     });
 };

  useEffect(() => {
    fetchAppService();
  }, []);

  useEffect(() => {
    // Initialize enable/disable status for each item when the component mounts
    const initialStatus = {};
    appServiceList.forEach((item) => {
      initialStatus[item.id] = item.status;
    });
    setEnableDisableStatus(initialStatus);
  }, [appServiceList]);

  const handleEnableDisable = (item) => {
    const updatedStatus = !item.status;
    
    // Update status locally
    const updatedAppServiceData = appServiceList.map((dataItem) => {
      if (dataItem.id === item.id) {
        return {
          ...dataItem,
          status: updatedStatus,
        };
      }
      return dataItem;
    });
    // Update enable/disable status
    setEnableDisableStatus((prevStatus) => ({
      ...prevStatus,
      [item.id]: updatedStatus,
    }));
    // Update order data state
    setAppServiceList(updatedAppServiceData);

    // Make API call to update status in backend
    // Example:
    const data = {
      ...item,
      status: updatedStatus,
    };
    updateAppServiceStatus(data);
  };

  const updateAppServiceStatus = (data) => {
    setSubmitButtonPressed(true);
    updateAppService(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          if (response.error) {
            fetchAppService();
            errorMessage("App Service Status Change Failed");
            setSubmitButtonPressed(false);
          } else {
            fetchAppService();
            successMessage("App Service Status Successfully Change");
            setSubmitButtonPressed(false);
          }
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value); // Update searchQuery state with the input value
  };
  // Function to filter orderData based on search query
  const filteredData = appServiceList
    .filter((item) =>
      item.name?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      // Assuming 'name' is a property of the garment object
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFileName(file ? file.name : ""); // Update the file name or clear it if no file is selected

    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  const onClickAppServiceLogoUpload = (item) => {
    setAppServicePreviousLogo(item.logo);
    setSelectedAppServiceId(item.id);
    setDisplayAppServiceLogoUpload(true);
  };
  const onHideAppServiceLogoUpload = () => {
    setFileName("");
    setSelectedImage(null);
    setDisplayAppServiceLogoUpload(false);
  };

  const renderDialogAppServiceLogoUpload = () => (
    <Dialog
      header="App Service Logo Upload"
      visible={displayAppServiceLogoUpload}
      onHide={() => onHideAppServiceLogoUpload()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <form onSubmit={handleAppServiceLogoUpload}>
          <div className="row mb-3">
            <div
              className="col-md-12"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <label for="file-upload" className="custom-file-upload">
                  <i className="pi pi-upload"></i> Choose App Service Logo
                </label>
                <input
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
              <div>
                <img
                  src={
                    selectedImage
                      ? selectedImage
                      : AppServicePreviousLogo
                      ? `data:image/png;base64,${AppServicePreviousLogo}`
                      : "/Img/Dry_Cleaning_Active.png"
                  }
                  alt=""
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                  }}
                />
                {fileName && (
                  <p
                    style={{
                      alignSelf: "center",
                      fontSize: 14,
                      fontWeight: 600,
                      margin: 10,
                    }}
                  >
                    {fileName}
                  </p>
                )}
              </div>
            </div>
          </div>
          {selectedImage && (
            <div className="col-md-12 text-center">
              <button
                type="submit"
                className="move-btn move-btn-su"
                disabled={submitButtonPressed}
              >
                Submit
              </button>
            </div>
          )}
        </form>
      </div>
    </Dialog>
  );

  const handleAppServiceLogoUpload = (event) => {
    event.preventDefault();
    const fileInput = event.target.querySelector('input[type="file"]');
    const logo = fileInput.files[0];

    if (!logo) {
      console.error("No file selected");
      return;
    }

    uploadAppServiceLogo(selectedAppServiceId, logo)
      .then((response) => {
        // handle the response
        fetchAppService();
        successMessage("App Service Logo Upload Successfully");
        setFileName("");
        setSelectedImage(null);
        setDisplayAppServiceLogoUpload(false);
      })
      .catch((error) => {
        // handle errors
        fetchAppService();
        errorMessage("App Service Logo Upload Failed");
        setFileName("");
        setSelectedImage(null);
        setDisplayAppServiceLogoUpload(false);
      });
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row className="mt-3 ml-3">
          <Col>
            <InputText
              type="text"
              value={searchQuery}
              onChange={handleSearchInputChange}
              placeholder="Search App Service..."
            />
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Link
              to="/app-service"
              className="move-btn-a move-btn-mu center-text"
              variant="primary"
              style={{
                backgroundColor: "#2b70d7", // Change background color if selected
                color: "white", // Change text color if selected
                textAlign: "center",
                width: 120,
              }}
            >
              Add Services
            </Link>
          </Col>
        </Row>
        <Row>
          <Col style={{ margin: 10 }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ color: "blue", fontSize: "20px" }}
                  ></i>{" "}
                  App Service List is Loading
                </h3>
              </div>
            ) : filteredData.length > 0 ? (
              <div>
                <div style={{ textAlign: "center" }}>
                  <h3>App Services List </h3>
                </div>
                <main id="main" className="main mb-3">
                  <section className="section">
                    <div className="col-lg-12">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>App Service Name</th>
                            <th>Icon</th>
                            <th>Edit</th>
                            <th>Enable/Disable</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>
                                <img
                                  src={
                                    item.logo
                                      ? `data:image/png;base64,${item.logo}`
                                      : "/Img/Dry_Cleaning_Active.png"
                                  }
                                  alt="logo"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                  }}
                                  onClick={() =>
                                    onClickAppServiceLogoUpload(
                                      item,
                                      "displayAppServiceLogoUpload"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() =>
                                    navigate("/app-service", {
                                      state: item,
                                    })
                                  }
                                >
                                  <i
                                    className="pi pi-pencil"
                                    style={{ color: "green", fontSize: "20px" }}
                                  ></i>
                                </button>
                              </td>
                              <td>
                                <InputSwitch
                                  // checked={enableDisableStatus[item.id]}
                                  // onChange={() => handleEnableDisable(item)}
                                  checked={item.status}
                                  onChange={() => handleEnableDisable(item)}
                                  disabled={submitButtonPressed}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </section>
                </main>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>No App Services Found</h3>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <div>{renderDialogAppServiceLogoUpload()}</div>
    </div>
  );
};

export default AppServicesView;
