
import axios from "axios";
import getHeaders from "../constants/headers";
import { PRICElIST_BASE_URL } from "./BaseURL";

export const getGarmentCategory = () => {
  return axios({
    method: "get",
    url:
      PRICElIST_BASE_URL +
      "/api/secure/v1/admin/garment/garment-category",
    headers: getHeaders(),
  });
};

export const garmentStatusUpdate = (data) => {
  return axios({
    method: "put",
    url:
      PRICElIST_BASE_URL +
      "/api/secure/v1/admin/garment/garment-category",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const addGarment = (data) => {
  return axios({
    method: "post",
    url:
      PRICElIST_BASE_URL +
      "/api/secure/v1/admin/garment",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const updateGarment = (data) => {
  return axios({
    method: "put",
    url:
      PRICElIST_BASE_URL +
      "/api/secure/v1/admin/garment",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const uploadLogo = (garmentId,logo) => {
  const formData = new FormData();
  formData.append("fileData", logo); // Assuming 'file' is the file object you want to upload

  return axios({
    method: "post",
    url: PRICElIST_BASE_URL + "/api/secure/v1/admin/garment/" + garmentId + "/logo",
    data: formData,
    headers: getHeaders("multipart/form-data"),
  });
};