import React, { useEffect, useState } from "react";
import { Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { MultiSelect } from "primereact/multiselect";
import { successMessage } from "../utils/alertMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import {
  addGarment,
  updateGarment,
} from "../services/garmentCategory";
import { addAppService, updateAppService } from "../services/appServices";

const AddAppService = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
     const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const [input, setInput] = useState({
    appServiceName: state?.name || "",
  });

  const [errors, setErrors] = useState({
    appServiceName: "",
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (input.appServiceName === "") {
      newErrors.appServiceName = "Please Fill App Service";
      isValid = false;
    } else {
      newErrors.appServiceName = "";
    }
    // Add more validation logic for other fields as needed

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    setSubmitButtonPressed(true);
    event.preventDefault();
    if (validateForm()) {

      if (state === null) {
        const data = {
          name: input.appServiceName,
          code: input.appServiceName,
          description: input.appServiceName,
          type: "main",
          logoKey: "appservice",
          logo: input.appServiceName + ".png",
          status: true,
        };
        addAppService(data)
          .then((response) => {
            if (!response.data.error) {
              const Res = response.data;
              successMessage("Garment Added Successfully");
              setSubmitButtonPressed(false);
              navigate("/view-app-services");
            }
          })
          .catch((err) => {
            console.error("Error:", err);
          });
      } else {
        const data = {
          logoKey: state.logoKey,
          logo: input.appServiceName + ".png",
          id: state.id,
          name: input.appServiceName,
          code: input.appServiceName,
          description: input.appServiceName,
          type: "main",
          status: state.status,
        };
         updateAppService(data)
           .then((response) => {
             if (!response.data.error) {
               const Res = response.data;
               successMessage("Garment Updated Successfully");
               setSubmitButtonPressed(false);
               navigate("/view-app-services");
             }
           })
           .catch((err) => {
             console.error("Error:", err);
           });
      }
    }
  };
  return (
    <div>
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div style={{ textAlign: "center" }}>
              <h3>{state !== null ? "Edit" : "Add"} App Service</h3>
            </div>
            <main id="main" className="main">
              <section className="section">
                <div className="row">
                  <div className="col-lg-12">
                    <Card
                      style={{
                        backgroundColor: "#1752AA",
                        color: "white",
                        marginTop: 20,
                      }}
                    >
                      <Card.Body>
                        <form onSubmit={handleSubmit}>
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label
                                htmlFor="appServiceName"
                                className="form-label"
                              >
                                App Service Name
                              </label>
                            </div>
                            <InputText
                              type="text"
                              style={{ width: "100%" }}
                              id="appServiceName"
                              name="appServiceName"
                              value={input.appServiceName}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  appServiceName: e.target.value,
                                });
                              }}
                              readonly
                            />
                            <div className="text-danger mt-1">
                              {errors.appServiceName}
                            </div>
                          </div>
                          <div className="col-md-12 text-center">
                            <button
                              type="submit"
                              className="move-btn-a move-btn-mu"
                              disabled={submitButtonPressed}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </section>
            </main>
          </Col>
          <Col style={{ textAlign: "center", marginTop: 50 }}>
            <img src="/Img/5.png" alt="logo" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddAppService;
