import axios from "axios";
import getHeaders from "../constants/headers";
import { BRAND_BASE_URL } from "./BaseURL";

export const getAllBrandRole = () => {
  return axios({
    method: "get",
    url: BRAND_BASE_URL + "/brand/api/v1/role",
    headers: getHeaders(),
  });
};

export const getAllBrands = () => {
  return axios({
    method: "get",
    url: BRAND_BASE_URL + "/brand/api/v1/brand",
    headers: getHeaders(),
  });
};

export const getSingleBrands = (brandId) => {
  return axios({
    method: "get",
    url: BRAND_BASE_URL + "/brand/api/v1/brand/" + brandId,
    headers: getHeaders(),
  });
};

export const addBrand = (data) => {
  return axios({
    method: "post",
    url: BRAND_BASE_URL + "/brand/api/v1/brand",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const updateBrand = (data) => {
  return axios({
    method: "put",
    url: BRAND_BASE_URL + "/brand/api/v1/brand",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const getBrandUsers = (brandId) => {
  return axios({
    method: "get",
    // url: BRAND_BASE_URL + "/brand/api/secure/v1/brand/" + brandId + "/user",
    url:
      BRAND_BASE_URL +
      "/brand/api/secure/v1/brand/" +
      brandId +
      "/user-by-sadmin",
    headers: getHeaders(),
  });
};

export const addAdminUser = (brandId, data) => {
  return axios({
    method: "post",
    url: BRAND_BASE_URL + "/brand/api/secure/v1/brand/" + brandId + "/user",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const updateAdminUser = (brandId, data) => {
  return axios({
    method: "put",
    url: BRAND_BASE_URL + "/brand/api/secure/v1/brand/" + brandId + "/user",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};
 


export const addActivateDeactivateUser = (brandId, data) => {
  return axios({
    method: "put",
    url: BRAND_BASE_URL + "/brand/api/secure/v1/brand/" + brandId + "/activate-user",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};