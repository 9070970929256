import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "../index.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {  getSingleBrands } from "../../services/brandApi";

const ViewBrandDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const brandId = state.id;

  const [loading, setLoading] = useState(false);
  const [brandInfo, setBrandInfo] = useState({});

  const fetchAllBrands = () => {
    setLoading(true);
    getSingleBrands(brandId)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setBrandInfo(Res);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchAllBrands();
  }, []);

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row className="mt-3 ml-3">
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Link
              to="/add-brand"
              className="move-btn-a move-btn-mu center-text"
              variant="primary"
              style={{
                backgroundColor: "#2b70d7", // Change background color if selected
                color: "white", // Change text color if selected
                textAlign: "center",
                width: 120,
              }}
            >
              Add Brand
            </Link>
          </Col>
        </Row>
        <Row>
          <Col style={{ margin: 10 }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ color: "blue", fontSize: "20px" }}
                  ></i>
                  Brand Info is Loading
                </h3>
              </div>
            ) : (
              <div>
                <div style={{ textAlign: "center" }}>
                  <h3>Brand {brandInfo.name}</h3>
                </div>
                <main id="main" className="main mb-3">
                  <section className="section">
                    <Row className="mt-3">
                      <Col style={{ marginLeft: 40, marginRight: 40 }}>
                        <Row
                          style={{
                            backgroundColor: "#ACE1AF",
                            paddingTop: 10,
                            paddingBottom: 1,
                            paddingLeft: 20,
                            borderRadius: 20,
                            cursor: "pointer",
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            transition: "0.3s",
                            marginBottom: 15,
                          }}
                        >
                          <Col>
                            <h5>Name</h5>
                          </Col>
                          <Col>
                            <h5>{brandInfo.name}</h5>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            backgroundColor: "#FFFF00",
                            paddingTop: 10,
                            paddingBottom: 1,
                            paddingLeft: 20,
                            borderRadius: 20,
                            cursor: "pointer",
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            transition: "0.3s",
                            marginBottom: 15,
                          }}
                        >
                          <Col>
                            <h5>Mobile No</h5>{" "}
                          </Col>
                          <Col>
                            <h5>{brandInfo.mobileNo}</h5>
                          </Col>
                        </Row>
                        <Row
                          className="display-row"
                          style={{
                            backgroundColor: "#DDA0DD",
                            paddingTop: 10,
                            paddingBottom: 1,
                            paddingLeft: 20,
                            borderRadius: 20,
                            cursor: "pointer",
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            transition: "0.3s",
                            marginBottom: 15,
                          }}
                        >
                          <Col>
                            <h5>Email</h5>
                          </Col>
                          <Col>
                            <h5>{brandInfo.email}</h5>
                          </Col>
                        </Row>
                        <Row
                          className="display-row"
                          style={{
                            backgroundColor: "#ACE1AF",
                            paddingTop: 10,
                            paddingBottom: 1,
                            paddingLeft: 20,
                            borderRadius: 20,
                            cursor: "pointer",
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            transition: "0.3s",
                            marginBottom: 15,
                          }}
                        >
                          <Col>
                            <h5>Notify Email</h5>{" "}
                          </Col>
                          <Col>
                            <h5>{brandInfo.notifyEmail}</h5>
                          </Col>
                        </Row>
                        <Row
                          className="display-row"
                          style={{
                            backgroundColor: "#FFFF00",
                            paddingTop: 10,
                            paddingBottom: 1,
                            paddingLeft: 20,
                            borderRadius: 20,
                            cursor: "pointer",
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            transition: "0.3s",
                            marginBottom: 15,
                          }}
                        >
                          <Col>
                            <h5>Notify Mobile No</h5>
                          </Col>
                          <Col>
                            <h5>{brandInfo.notifyMobileNo}</h5>
                          </Col>
                        </Row>
                        <Row
                          className="display-row"
                          style={{
                            backgroundColor: "#DDA0DD",
                            paddingTop: 10,
                            paddingBottom: 1,
                            paddingLeft: 20,
                            borderRadius: 20,
                            cursor: "pointer",
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            transition: "0.3s",
                            marginBottom: 15,
                          }}
                        >
                          <Col>
                            <h5>Address</h5>
                          </Col>
                          <Col>
                            <h5>{brandInfo.address?.fullAddress}</h5>
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ marginLeft: 40, marginRight: 40 }}>
                        <Row
                          className="display-row"
                          style={{
                            backgroundColor: "#ACE1AF",
                            paddingTop: 10,
                            paddingBottom: 1,
                            paddingLeft: 20,
                            borderRadius: 20,
                            cursor: "pointer",
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            transition: "0.3s",
                            marginBottom: 15,
                          }}
                        >
                          <Col>
                            <h5>Ware House Model</h5>
                          </Col>
                          <Col>
                            <h5>
                              {brandInfo.wareHouseModel ? "True" : "False"}
                            </h5>
                          </Col>
                        </Row>
                        <Row
                          className="display-row"
                          style={{
                            backgroundColor: "#FFFF00",
                            paddingTop: 10,
                            paddingBottom: 1,
                            paddingLeft: 20,
                            borderRadius: 20,
                            cursor: "pointer",
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            transition: "0.3s",
                            marginBottom: 15,
                          }}
                        >
                          <Col>
                            <h5>Offer Price Enabled</h5>
                          </Col>
                          <Col>
                            <h5>
                              {brandInfo.offerPriceEnabled ? "True" : "False"}
                            </h5>
                          </Col>
                        </Row>
                        <Row
                          className="display-row"
                          style={{
                            backgroundColor: "#DDA0DD",
                            paddingTop: 10,
                            paddingBottom: 1,
                            paddingLeft: 20,
                            borderRadius: 20,
                            cursor: "pointer",
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            transition: "0.3s",
                            marginBottom: 15,
                          }}
                        >
                          <Col>
                            <h5>Brand Off Model</h5>
                          </Col>
                          <Col>
                            <h5>
                              {brandInfo.brandOffModel ? "True" : "False"}
                            </h5>
                          </Col>
                        </Row>
                        <Row
                          className="display-row"
                          style={{
                            backgroundColor: "#ACE1AF",
                            paddingTop: 10,
                            paddingBottom: 1,
                            paddingLeft: 20,
                            borderRadius: 20,
                            cursor: "pointer",
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            transition: "0.3s",
                            marginBottom: 15,
                          }}
                        >
                          <Col>
                            <h5>Order Edit Model</h5>
                          </Col>
                          <Col>
                            <h5>
                              {brandInfo.orderEditModel ? "True" : "False"}
                            </h5>
                          </Col>
                        </Row>
                        <Row
                          className="display-row"
                          style={{
                            backgroundColor: "#FFFF00",
                            paddingTop: 10,
                            paddingBottom: 1,
                            paddingLeft: 20,
                            borderRadius: 20,
                            cursor: "pointer",
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            transition: "0.3s",
                            marginBottom: 15,
                          }}
                        >
                          <Col>
                            <h5>Web Login Enabled</h5>
                          </Col>
                          <Col>
                            <h5>
                              {brandInfo.webLoginEnabled ? "True" : "False"}
                            </h5>
                          </Col>
                        </Row>
                        <Row
                          className="display-row"
                          style={{
                            backgroundColor: "#FFFF00",
                            paddingTop: 10,
                            paddingBottom: 1,
                            paddingLeft: 20,
                            borderRadius: 20,
                            cursor: "pointer",
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            transition: "0.3s",
                            marginBottom: 15,
                          }}
                        >
                          <Col>
                            <h5>Enabled Garment Process Stage</h5>
                          </Col>
                          <Col>
                            <h5>
                              {brandInfo.enabledGarmentProcessStage
                                ? "True"
                                : "False"}
                            </h5>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </section>
                </main>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ViewBrandDetails;
