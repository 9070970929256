import axios from "axios";
import { PRICElIST_BASE_URL } from "./BaseURL";
import getHeaders from "../constants/headers";

export const getAppService = () => {
  return axios({
    method: "get",
    url: PRICElIST_BASE_URL + "/api/secure/v2/app-service",
    headers: getHeaders(),
  });
};

export const addAppService = (data) => {
  return axios({
    method: "post",
    url: PRICElIST_BASE_URL + "/api/secure/v1/app-service",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const updateAppService = (data) => {
  return axios({
    method: "put",
    url: PRICElIST_BASE_URL + "/api/secure/v1/app-service",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const uploadAppServiceLogo = (appServiceId, logo) => {
  const formData = new FormData();
  formData.append("fileData", logo); // Assuming 'file' is the file object you want to upload

  return axios({
    method: "post",
    url:
      PRICElIST_BASE_URL +
      "/api/secure/v1/app-service/" +
      appServiceId +
      "/logo",
    data: formData,
    headers: getHeaders("multipart/form-data"),
  });
};
// secure / v1 / app - service / { appServiceId } / logo;

