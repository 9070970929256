import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./index.css";

import React, { useState } from "react";
import { Button } from "primereact/button";
import "./FormDemo.css";
import { InputText } from "primereact/inputtext";
import {
  brandProfile,
  getStoreUser,
  signIn,
  userProfile,
} from "../services/SignIn";
import { setUserData } from "../utils/localStorage";
import { setToken } from "../redux/authSlice";
import { errorMessage } from "../utils/alertMessage";
import { useDispatch } from "react-redux";
import { Alert } from "bootstrap";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const dispatch = useDispatch();

  const [input, setInput] = useState({
    user: "",
    pass: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await signIn({
        username: input.user,
        password: input.pass,
        source: "web",
        version: "2.1",
      });

      if (response && response.data && !response.data.error) {
        const token = response.data.accessToken;
        const userResponse = await userProfile(token);

        if (userResponse && userResponse.data && !userResponse.data.error) {
          const userProfileData = userResponse.data;
          if (
            [
              "ROLE_SUPER_ADMIN",
            ].includes(userProfileData.role)
          ) {
            const brandResponse = await brandProfile(token);

            if (
              brandResponse &&
              brandResponse.data &&
              !brandResponse.data.error
            ) {
              const brandProfileData = brandResponse.data;
              let storeData = {};

              // if (userProfileData.role !== "ROLE_ADMIN") {
              //   const storeResponse = await getStoreUser(
              //     userProfileData.id,
              //     token
              //   );

              //   if (storeResponse && !storeResponse.data.error) {
              //     storeData = storeResponse.data.store;
              //   } else {
              //     console.error("Error fetching store user");
              //   }
              // }

              setUserData({
                token,
                userProfileData,
                brandProfileData,
                storeData,
              });
              dispatch(setToken(token));
            } else {
              console.error("Error fetching brand profile");
            }
          } else {
            errorMessage("Hello User, You don't have a access");
            
          }
        } else {
          console.error("Error fetching user profile");
        }
      } else {
        console.error("Error:", response?.data?.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error:", error.message);
      errorMessage();
    }
  };

  return (
    <div className="form-demo background-image-login">
      <div className="flex justify-content-center mt-3">
        <div
          className="card"
          style={{ border: "none", backgroundColor: "transparent" }}
        >
          <div style={{ textAlign: "center", marginBottom: 10 }}>
            <img style={{ width: "216px" }} src="/Img/1.png" alt="logo" />
          </div>
          <h5 className="text-center">DhobiCart Login</h5>
          <form onSubmit={handleSubmit} className="p-fluid">
            <div className="field">
              <span className="p-float-label">
                <h5>UserName</h5>
                <div className="input-group has-validation">
                  <InputText
                    value={input.user}
                    onChange={(e) => {
                      setInput({
                        ...input,
                        user: e.target.value,
                      });
                    }}
                    type="text"
                    name="username"
                    className="form-control"
                    id="username"
                  />
                </div>
              </span>
            </div>

            <div className="field">
              <span className="p-float-label">
                <h5>Password</h5>
                <div className="input-group has-validation">
                  <InputText
                    value={input.pass}
                    onChange={(e) => {
                      setInput({
                        ...input,
                        pass: e.target.value,
                      });
                    }}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="form-control"
                    id="password"
                  />
                  {/* Move the eye icon inside the input box */}
                  <span
                    className="input-group-addon"
                    onClick={handleTogglePassword}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      left: "-10px",
                      marginLeft: "-15px",
                    }}
                  >
                    <i
                      className={`pi ${
                        !showPassword ? "pi-eye-slash" : "pi-eye"
                      }`}
                    />
                  </span>
                </div>
              </span>
            </div>
            <Button type="submit" label="Submit" className="mt-2" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
