import React, { useEffect, useState } from "react";
import { Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { MultiSelect } from "primereact/multiselect";
import { errorMessage, successMessage } from "../utils/alertMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import {
  addGarment,
  updateGarment,
} from "../services/garmentCategory";

const AddGarment = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
   const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const [fileName, setFileName] = useState("");
  const [garmentStatus, setGarmentStatus] = useState({
    name: "True",
    value: true,
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [input, setInput] = useState({
    category: state?.category.name || "",
    garmentName: state?.garment.name || "",
    qrCodeCount: state?.garment.qrCodeCount || "",
  });

  const [errors, setErrors] = useState({
    category: "",
    garmentName: "",
    qrCodeCount: "",
  });

  const categoryList = [
    {
      name: "MEN",
      value: "MEN",
    },
    {
      name: "WOMEN",
      value: "WOMEN",
    },
    {
      name: "KID",
      value: "KID",
    },
    {
      name: "OTHER",
      value: "OTHER",
    },
  ];

  const statusDropdown = [
    {
      name: "True",
      value: true,
    },
    {
      name: "False",
      value: false,
    },
  ];

  const handleStatusChange = (e) => {
    setGarmentStatus(e.value);
  };

 useEffect(() => {
   if (state !== null) {
     setGarmentStatus({ name: state.garment.status ? "True" : "False", value: state.garment.status }); // Set initial status from state
   }
 }, [state]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (input.category === "") {
      newErrors.category = "Please select any App Service List";
      isValid = false;
    } else {
      newErrors.category = "";
    }
    if (input.garmentName === "") {
      newErrors.garmentName = "Please select any Service Unit";
      isValid = false;
    } else {
      newErrors.garmentName = "";
    }
    if (input.qrCodeCount === "") {
      newErrors.qrCodeCount = "Please select any Tariff List";
      isValid = false;
    } else {
      newErrors.qrCodeCount = "";
    }
    // Add more validation logic for other fields as needed

    setErrors(newErrors);
    return isValid;
  };

  // const handleFileChange = (event) => {
  //   event.preventDefault();
  //   const file = event.target.files[0];
  //   setFileName(file ? file.name : ""); // Update the file name or clear it if no file is selected

  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     setSelectedImage(reader.result);
  //   };
  //   if (file) {
  //     reader.readAsDataURL(file);
  //   } else {
  //     setSelectedImage(null);
  //   }
  // };

  const handleSubmit = async (event) => {
    setSubmitButtonPressed(true);
    event.preventDefault();
    if (validateForm()) {

      if (state === null) {
        const data = {
          name: input.garmentName,
          code: input.garmentName,
          description: input.garmentName,
          qrCodeCount: input.qrCodeCount,
          logoKey: "garment",
          status: true,
          categories: input.category,
        };
        addGarment(data)
          .then((response) => {
            if (!response.data.error) {
              const Res = response.data;
              successMessage("Garment Added Successfully");
              setSubmitButtonPressed(false);
               navigate("/view-garment")
            }
          })
          .catch((err) => {
            console.error("Error:", err);
          });
      } else {
        const data = {
          logoKey: state.garment.logoKey,
          id: state.garment.id,
          name: input.garmentName,
          code: input.garmentName,
          description: input.garmentName,
          qrCodeCount: input.qrCodeCount,
          status: garmentStatus.value,
          categories: [input.category],
        };
         updateGarment(data)
           .then((response) => {
             if (!response.data.error) {
               const Res = response.data;
               setSubmitButtonPressed(false);
               successMessage("Garment Updated Successfully");
               navigate("/view-garment");
             }
           })
           .catch((err) => {
             console.error("Error:", err);
           });
      }
    }
  };
  return (
    <div>
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div style={{ textAlign: "center" }}>
              <h3>{state !== null ? "Edit" : "Add"} Garment</h3>
            </div>
            <main id="main" className="main">
              <section className="section">
                <div className="row">
                  <div className="col-lg-12">
                    <Card
                      style={{
                        backgroundColor: "#1752AA",
                        color: "white",
                        marginTop: 20,
                      }}
                    >
                      <Card.Body>
                        <form onSubmit={handleSubmit}>
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label
                                htmlFor="garmentName"
                                className="form-label"
                              >
                                Garment Name
                              </label>
                            </div>
                            <InputText
                              type="text"
                              style={{ width: "100%" }}
                              id="garmentName"
                              name="garmentName"
                              value={input.garmentName}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  garmentName: e.target.value,
                                });
                              }}
                              readonly
                            />
                            <div className="text-danger mt-1">
                              {errors.garmentName}
                            </div>
                          </div>
                          {state === null && (
                            <div className="row mb-3">
                              <div className="col-md-12">
                                <label
                                  htmlFor="category"
                                  className="form-label"
                                >
                                  Category
                                </label>
                              </div>
                              <MultiSelect
                                display="chip"
                                value={input.category}
                                options={categoryList}
                                optionLabel="name"
                                onChange={(e) => {
                                  setInput({
                                    ...input,
                                    category: e.target.value,
                                  });
                                }}
                              />
                              <div className="text-danger mt-1">
                                {errors.category}
                              </div>
                            </div>
                          )}
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label
                                htmlFor="qrCodeCount"
                                className="form-label"
                              >
                                QrCode Count
                              </label>
                            </div>
                            <InputText
                              type="number"
                              style={{ width: "100%" }}
                              id="qrCodeCount"
                              name="qrCodeCount"
                              value={input.qrCodeCount}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  qrCodeCount: e.target.value,
                                });
                              }}
                              readonly
                            />
                            <div className="text-danger mt-1">
                              {errors.qrCodeCount}
                            </div>
                          </div>
                          {state !== null && (
                            <div>
                              <div className="row mb-3">
                                <div className="col-md-12">
                                  <label
                                    htmlFor="qrCodeCount"
                                    className="form-label"
                                  >
                                    Category
                                  </label>
                                </div>
                                <InputText
                                  type="text"
                                  style={{ width: "100%" }}
                                  id="Category"
                                  name="Category"
                                  value={input.category}
                                  readonly={true}
                                />
                              </div>
                              <div className="row mb-3">
                                <div className="col-md-12">
                                  <label
                                    htmlFor="qrCodeCount"
                                    className="form-label"
                                  >
                                    Garment Status
                                  </label>
                                </div>
                                <Dropdown
                                  value={garmentStatus}
                                  options={statusDropdown}
                                  optionLabel="name"
                                  onChange={handleStatusChange}
                                  placeholder="Select a Status"
                                />
                                <div className="text-danger mt-1">
                                  {errors.qrCodeCount}
                                </div>
                              </div>
                            </div>
                          )}
                          {/*<div className="row mb-3">
                            <div
                              className="col-md-12"
                              style={{
                                display: "flex",
                              }}
                            >
                              <label
                                for="file-upload"
                                className="custom-file-upload"
                              >
                                <i className="pi pi-upload"></i> Choose Garment
                                Icon
                              </label>
                              <input
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                              />
                            </div>
                            </div>*/}
                          <div className="col-md-12 text-center">
                            <button
                              type="submit"
                              className="move-btn-a move-btn-mu"
                              disabled={submitButtonPressed}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </section>
            </main>
          </Col>
          <Col style={{ textAlign: "center", marginTop: 50 }}>
            {selectedImage ? (
              <div>
                <div>
                  <img
                    src={selectedImage}
                    alt=""
                    style={{
                      maxWidth: "400px",
                      maxHeight: "400px",
                    }}
                  />
                </div>

                <p
                  style={{
                    alignSelf: "center",
                    fontSize: 14,
                    fontWeight: 600,
                    margin: 10,
                  }}
                >
                  {fileName}
                </p>
              </div>
            ) : (
              <img src="/Img/5.png" alt="logo" />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddGarment;
