import axios from "axios";
import getHeaders from "../constants/headers";
import { BRAND_BASE_URL } from "./BaseURL";

export const closeOrderInfo = (brandId, storeId, data) => {
  return axios({
    method: "post",
    url:
      BRAND_BASE_URL +
      "/store/api/secure/v1/brand/" +
      brandId +
      "/store/" +
      storeId +
      "/close-order-info",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const closeOrder = (brandId, storeId, data) => {
  return axios({
    method: "post",
    url:
      BRAND_BASE_URL +
      "/store/api/secure/v1/brand/" +
      brandId +
      "/store/" +
      storeId +
      "/close-order",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};


// curl --location 'https://dhobicart.in/store/api/secure/v1/brand/1/store/1/close-order-info' \
// --header 'Authorization: Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRob3JpdGllcyI6WyJST0xFX1NVUEVSX0FETUlOIl0sImRldmljZVRva2VuIjoiZDQxZDhjZDk4ZjAwYjIwNGU5ODAwOTk4ZWNmODQyN2UiLCJsb2dnZWRJblVzZXJJZCI6Ik9JVDM3NTcyIiwibG9nZ2VkSW5Vc2VyQnJhbmRJZCI6IjEiLCJzdWIiOiIxIiwiaWF0IjoxNzI1NTIwODI0LCJleHAiOjE3MjYxMjU2MjR9.D-_whnPNAbJmUemV9UiQiP6RaBq0juudN63kJBTX-od5oFNnp5tgrRGcowN5bkQkH9IDbEqan3CpB2nbdPuxtQ' \
// --header 'Content-Type: application/json' \
// --header 'Cookie: JSESSIONID=7F85D33C3FA7BDB2E024D1FF01D37852; JSESSIONID=0E1530F3BE91EB8D1572B10C00D17B19; JSESSIONID=0FABE52CBD22409C52490DEDBE4D1A04' \
// --data '{
//     "status" : "PROCESSED",
//     "invoiceList" :["1532"]
// }'


// curl --location 'https://dhobicart.in/store/api/secure/v1/brand/1/store/1/close-order' \
// --header 'Authorization: Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRob3JpdGllcyI6WyJST0xFX1NVUEVSX0FETUlOIl0sImRldmljZVRva2VuIjoiZDQxZDhjZDk4ZjAwYjIwNGU5ODAwOTk4ZWNmODQyN2UiLCJsb2dnZWRJblVzZXJJZCI6Ik9JVDM3NTcyIiwibG9nZ2VkSW5Vc2VyQnJhbmRJZCI6IjEiLCJzdWIiOiIxIiwiaWF0IjoxNzI1NTIwODI0LCJleHAiOjE3MjYxMjU2MjR9.D-_whnPNAbJmUemV9UiQiP6RaBq0juudN63kJBTX-od5oFNnp5tgrRGcowN5bkQkH9IDbEqan3CpB2nbdPuxtQ' \
// --header 'Content-Type: application/json' \
// --header 'Cookie: JSESSIONID=7F85D33C3FA7BDB2E024D1FF01D37852; JSESSIONID=0E1530F3BE91EB8D1572B10C00D17B19; JSESSIONID=75556340188F3E970DF2C2940F244AE9' \
// --data '{
//     "status" : "DELIVERED",
//     "invoiceList" :["1532"]
// }'